import React from "react";
import Layout from "component/Layout/Layout";
import Seo from "component/seo";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import DiscussSection from "component/Discuss/Discuss";
import SidebarRight from "component/Sidebar/SidebarRight";
import { useSiteUrl } from "utils";

export default function Discuss({ location }) {
  const canonicalUrl = `${useSiteUrl()}${location.pathname}`;

  return (
    <Layout>
      <Seo titleSample={true} title="Discuss" canonicalUrl={canonicalUrl} />
      <SidebarLeft />
      <DiscussSection />
      <SidebarRight />
    </Layout>
  );
}

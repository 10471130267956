import React from "react";
import "./Discuss.scss";
import { BsFillLockFill } from "react-icons/bs";
import { StaticImage } from "gatsby-plugin-image";
export default function DiscussSection() {
  return (
    <section className="discuss-section">
      <h1 className="header-title">
        Các bạn có thể thảo luận các vấn đề về Front-end tại group này{" "}
        <span role="img" aria-label="smile">
          😁
        </span>
      </h1>
      <div className="group-info">
        <div className="image">
          <a
            href="https://www.facebook.com/groups/790171071604282"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="https://www.facebook.com/images/groups/groups-default-cover-photo-2x.png"
              alt="homiedev group"
              loading="lazy"
              aspectRatio={235 / 87}
              placeholder="blurred"
              width={768}
            />
          </a>
        </div>
        <div className="footer-content">
          <div className="group">
            <div className="name">
              <h1>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/groups/790171071604282"
                >
                  Homiedev - Hi Front-end <br /> Developer
                </a>
                <span role="img" aria-label="rocket">
                  🚀
                </span>{" "}
              </h1>
            </div>
            <div className="icon">
              <BsFillLockFill />
              <span>Nhóm riêng tư</span>
            </div>
          </div>
          <div className="join-link">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/groups/790171071604282"
              className=""
            >
              <h2>
                Join{" "}
                <span role="img" aria-label="fire">
                  🔥
                </span>
              </h2>
            </a>
          </div>
        </div>
      </div>
      {/* end group */}
      <div className="page-info">
        <p>
          Ngoài ra, mình có lập một{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/HomiedevBlog/"
            className="link"
          >
            Page
          </a>{" "}
          nho nhỏ về front-end{" "}
          <span role="img" aria-label="smile">
            😁
          </span>
          .
        </p>
        <p>
          Các bạn có thể like page để theo dõi các bài tutorial hay về Front-end
          nhé{" "}
          <span role="img" aria-label="fire">
            🔥
          </span>
        </p>
      </div>
    </section>
  );
}
